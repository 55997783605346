import React from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import {navigate} from "gatsby";
import appContent from "../../../../markdown/app-content";
import useCopyCurrentLink from "../../../../hooks/useCopyCurrentLink";
import {MarkdownPageLayout} from "../../../../components/components";
import ApplyCustomGameTheme from "../../shared/apply-custom-game-theme/ApplyCustomGameTheme";

const CurrentLinkNoTeamFoundContent = ({}) => {
  const {copyCurrentLink} = useCopyCurrentLink();
  return <MarkdownPageLayout
    logo="lg"
    markdown={appContent.inGame.currentLinkRequiresTeam.markdown}
  >
    <Button block variant="secondary" onClick={copyCurrentLink}>{appContent.inGame.currentLinkRequiresTeam.copyLink}</Button>
    <Button block variant="dark" onClick={() => navigate("/")}>{appContent.inGame.currentLinkRequiresTeam.goHomeButtonText}</Button>
    <Button block variant="dark" onClick={() => navigate(appContent.inGame.currentLinkRequiresTeam.goToLearnMoreLink)}>{appContent.inGame.currentLinkRequiresTeam.goToLearnMore}</Button>
    <ApplyCustomGameTheme usePrimaryGame={true}/>
  </MarkdownPageLayout>;
};

CurrentLinkNoTeamFoundContent.propTypes = {};

export default CurrentLinkNoTeamFoundContent;
