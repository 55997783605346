import React, {useCallback, useContext, useEffect, useState} from "react";
import GameContext from "../context/game-context";
import {StringParam, useQueryParam} from "use-query-params";
import {AppAlertsContext} from "../context/app-alerts-context";
import {navigate} from "gatsby";
import {FOUND_COIN_NOT_LOGGED_IN_REDIRECT} from "../config/config-options";
import {applyCoinBonus} from "../services/firestore/functions/coins";
import {AlertType} from "../components/providers/app-alerts/AppAlerts";
import appContent from "../markdown/app-content";
import {CrossTabContext} from "../context/cross-tab-context";
import {USE_CLUE_ADVANCE_CHECK_RESULT} from "./useClueAdvanceCheck";
import {MarkdownPageLayout} from "../components/components";


const useCoinCheck = () => {
  const {game, team, isLoading} = useContext(GameContext);
  const [code] = useQueryParam("code", StringParam);
  const {popAlert, popError} = useContext(AppAlertsContext);
  const [isProcessing, setIsProcessing] = useState(true);
  const {checkCoin} = useContext(CrossTabContext);
  const [showContent, setShowContent] = useState(null);

  const processCodeCrossTab = () => {
    checkCoin(code)
      .then((response) => {
        let markdownContent;
        if (response === "success")
          markdownContent = appContent.alerts.redeemCoinSuccessCrossTabMarkdown;
        else if (response === "invalid-argument")
          markdownContent = appContent.alerts.redeemCoinErrorCrossTabMarkdown;
        else if (response === "unavailable")
          markdownContent = appContent.alerts.redeemCoinAlreadyTakenCrossTabMarkdown;
        else
          markdownContent = appContent.alerts.redeemCoinErrorCrossTabMarkdown;

        if (markdownContent) {
          setShowContent(<MarkdownPageLayout logo="lg" markdown={markdownContent}/>);
        }
        setIsProcessing(false);
      })
      .catch((e) => {
        setShowContent(<MarkdownPageLayout logo="lg" markdown={appContent.alerts.redeemCoinErrorCrossTabMarkdown}/>);
        setIsProcessing(false);
      });
  }

  const processCode = useCallback(async ({game, team, code}) => {
    try {
      await applyCoinBonus({team, phrase: code});
      try {
        popAlert(appContent.alerts.redeemCoinSuccess(game), AlertType.SUCCESS);
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      if (e.code === "invalid-argument") {
        popError(appContent.alerts.redeemCoinError);
      } else if (e.code === "unavailable") {
        popAlert(appContent.alerts.redeemCoinAlreadyTaken, AlertType.WARNING);
      } else if (e.code === "failed-precondition") {
        popAlert(appContent.alerts.redeemCoinTeamHasFinished, AlertType.WARNING);
      } else {
        popError("An unknown error occurred. We could not check this code for bonus time.");
      }
    }

    setIsProcessing(false);
    navigate("/game/in-game");
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    // Not logged in, try in another tab
    if (!game || !team) {
      processCodeCrossTab()
    }
    // Logged in normal flow
    else {
      processCode({game, team, code});
    }
  }, [isLoading, code]);

  return {isProcessing, showContent};
};

export default useCoinCheck;
