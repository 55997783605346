import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import CurrentLinkNoTeamFoundContent from "./CurrentLinkNoTeamFoundContent";
import GameContext from "../../../../context/game-context";
import {LoadingSpinner} from "../../../../components/components";
import Proptypes from "prop-types";
import {CrossTabContext} from "../../../../context/cross-tab-context";

const REQUIRES_TEAM_STATES = {
  loading: "loading",
  hasTeam: "has-team",
  noTeam: "no-team",
}

const CurrentLinkRequiresTeam = ({onHasTeam, children}) => {
  const {team, isLoading} = useContext(GameContext);
  const [requiresTeamState, setRequiresTeamState] = useState(REQUIRES_TEAM_STATES.loading);
  const {checkTeams} = useContext(CrossTabContext)

  useEffect(() => {
    if (!isLoading && requiresTeamState !== REQUIRES_TEAM_STATES.hasTeam) {
      if (team) {
        setRequiresTeamState(REQUIRES_TEAM_STATES.hasTeam);
      } else {
        checkTeams()
          .then(() => {
            console.warn("Has Team In Other Tab");
            setRequiresTeamState(REQUIRES_TEAM_STATES.hasTeam);
          })
          .catch(() => setRequiresTeamState(REQUIRES_TEAM_STATES.noTeam));
      }
    }
  }, [team, isLoading, onHasTeam]);

  return <LoadingSpinner isLoading={requiresTeamState === REQUIRES_TEAM_STATES.loading}>
    <div style={{minHeight: "100vh"}}>
      {requiresTeamState === REQUIRES_TEAM_STATES.hasTeam && children}
      {requiresTeamState === REQUIRES_TEAM_STATES.noTeam && <CurrentLinkNoTeamFoundContent/>}
    </div>
  </LoadingSpinner>
};

CurrentLinkRequiresTeam.propTypes = {
  children: Proptypes.node, // Shown when has a team
};

export default CurrentLinkRequiresTeam;
