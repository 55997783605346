import React from "react";
import CoinCheck from "../../app/game/in-game/coin/coin-check";
import CurrentLinkRequiresTeam from "../../app/game/in-game/current-link-requires-team/CurrentLinkRequiresTeam";

const CoinPage = () => {
  return <CurrentLinkRequiresTeam>
    <CoinCheck />
  </CurrentLinkRequiresTeam>;
};

export default CoinPage;
