import useCopyToClipboard from "./useCopyToClipboard";

const useCopyCurrentLink = () => {
  const {copyText} = useCopyToClipboard();

  return {
    copyCurrentLink: () => {
      copyText(window.location.href)
    },
  }
}

export default useCopyCurrentLink;
