import React from "react";
import {LoadingSpinner} from "../../../../components/components";
import ApplyCustomGameTheme from "../../shared/apply-custom-game-theme/ApplyCustomGameTheme";
import useCoinCheck from "../../../../hooks/useCoinCheck";

const CoinCheck = () => {
  const {isProcessing, showContent} = useCoinCheck();
  return <LoadingSpinner isLoading={isProcessing}>
    {showContent}
    <ApplyCustomGameTheme/>
  </LoadingSpinner>;
};

export default CoinCheck;
